import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "./LoginPopup.module.css";
import { useDispatch } from "react-redux";
import { loginUserAsync } from "../../store/authSlice";
import SignupPopup from "./SignupPopup"; // Import SignupPopup

const LoginPopup = ({ onClose }) => {
  const [showSignup, setShowSignup] = useState(false);
  const [form] = Form.useForm(); // Create a form instance
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    try {
      await dispatch(
        loginUserAsync({
          phoneNumber: values.phoneNumber,
          password: values.password,
          onClose,
        })
      );
      navigate("/home");
    } catch (error) {
      notification.error({
        message: "Login Failed",
        description:
          error.message || "An error occurred. Please try again later.",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "Login Failed",
      description: "Please check your credentials and try again.",
    });
  };

  // Toggle to show Signup
  const handleSwitchToSignup = () => {
    setShowSignup(true);
    form.resetFields(); // Clear input fields when switching
  };

  // Toggle to show Login
  const handleSwitchToLogin = () => {
    setShowSignup(false);
    form.resetFields(); // Clear input fields when switching
  };

  // Show SignupPopup if showSignup is true
  if (showSignup) {
    return <SignupPopup onClose={onClose} onSwitchToLogin={handleSwitchToLogin} />;
  }

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <CloseOutlined />
        </button>
        <h2 className={styles.title}>Login</h2>
        <Form
          name="login"
          form={form} // Bind the form instance
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={styles.form}
        >
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter your phone number!" },
              {
                pattern: /^\d{10}$/,
                message: "Please enter a valid 10-digit phone number!",
              },
            ]}
          >
            <Input
              placeholder="Phone Number"
              className={styles.input}
              type="tel"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password placeholder="Password" className={styles.input} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitButton}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <p className={styles.switchText}>
          Don't have an account?{" "}
          <span onClick={handleSwitchToSignup}>Sign Up</span>
        </p>
      </div>
    </div>
  );
};

export default LoginPopup;
