import { getToken } from "./token";

const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const placeBids = async (mid,bidData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/bids?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(bidData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while creating order:", error.message);
    throw error;
  }
};

const getAllBids = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/bids`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch bids");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching bids:", error.message);
    throw error;
  }
};

export { getAllBids, placeBids };
