import { getToken } from "./token";
const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const createOrder = async (amount) => {
  console.log(amount);
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/payment/create-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(amount),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create order");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while creating order:", error.message);
    throw error;
  }
};

const checkOrderStatus = async (orderId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/payment/order-status/${orderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch order-status");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while fetching order status:", error.message);
    throw error;
  }
};

export { createOrder, checkOrderStatus };
