


import React from "react";
import { FaTrophy, FaGamepad, FaChartLine, FaCoins } from "react-icons/fa";
import styles from "./Statistics.module.css";

const Statistics = ({isSidebarOpen}) => {
  const data = {
    totalBids: 1250,
    winningPercentage: 48.5,
    mostPlayedGame: "Kalyan Matka",
    highestBid: 10000,
    totalPoints: 750000,
    gamesPlayed: 45,
    averageBid: 540,
  };
  return (
    <div 
    className={`${styles.statisticsContainer} ${
      isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
    }`}
    >
      <div className={`${styles.statsCard} ${styles.totalBids}`}>
        <FaTrophy className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Total Bids</h3>
          <p className={styles.statsValue}>{data.totalBids}</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.winningPercentage}`}>
        <FaChartLine className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Winning Percentage</h3>
          <p className={styles.statsValue}>{data.winningPercentage}%</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.mostPlayedGame}`}>
        <FaGamepad className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Most Played Game</h3>
          <p className={styles.statsValue}>{data.mostPlayedGame}</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.highestBid}`}>
        <FaCoins className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Highest Bid</h3>
          <p className={styles.statsValue}>₹{data.highestBid.toLocaleString()}</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.totalPoints}`}>
        <FaCoins className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Total Points</h3>
          <p className={styles.statsValue}>{data.totalPoints.toLocaleString()}</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.gamesPlayed}`}>
        <FaGamepad className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Games Played</h3>
          <p className={styles.statsValue}>{data.gamesPlayed}</p>
        </div>
      </div>

      <div className={`${styles.statsCard} ${styles.averageBid}`}>
        <FaCoins className={styles.icon} />
        <div className={styles.statsContent}>
          <h3 className={styles.statsTitle}>Average Bid</h3>
          <p className={styles.statsValue}>₹{data.averageBid.toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;



