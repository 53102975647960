// src/components/common/PrivateRoute.js
import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {

  const { isLoggedIn,user } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    return (
      <>
        <Navigate to="/login" />
      </>
    );
  }

  return children;
};

export default PrivateRoute;
