import React from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "../components/common/PrivateRoute";

import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import GamesList from "../components/LandingPage/GamesListSection";
import PlayGame from "../components/LandingPage/PlayGame";
import AddFund from "../components/funds/AddFund";
import FundHistory from "../components/funds/FundHistory";
import BidHistory from "../components/bids/BidHistory";
import TopWinners from "../components/winners/TopWinners";
import TransactionHistory from "../components/transaction/TransactionHistory";
import Statistics from "../components/statistics/Statistics";
import BidPlacementForm from "../components/LandingPage/BidPlacementForm";
import CashfreePayment from "../components/funds/CashfreePayment";
import PaymentStatus from "../components/funds/PaymentStatus";
import ProfilePage from "../pages/ProfilePage";

const PrivateRoutes = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <GamesList isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
        <Route
          path="/play"
          element={
            <PrivateRoute>
              <PlayGame isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
        <Route
          path="/funds"
          element={
            <PrivateRoute>
              <FundHistory isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
        <Route
          path="/bid-history"
          element={
            <PrivateRoute>
              <BidHistory isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
        <Route
          path="/top-winners"
          element={
            <PrivateRoute>
              <TopWinners isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/transactions"
          element={
            <PrivateRoute>
              <TransactionHistory isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/statistics"
          element={
            <PrivateRoute>
              <Statistics isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-fund"
          element={
            <PrivateRoute>
              <AddFund isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <CashfreePayment isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment-status"
          element={
            <PrivateRoute>
              <PaymentStatus isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/bid-placement"
          element={
            <PrivateRoute>
              <BidPlacementForm isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage isSidebarOpen={isSidebarOpen} />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
