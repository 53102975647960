import React, { useEffect, useState } from "react";
import {
  FaMoneyBillWave,
  FaGamepad,
  FaCalendarAlt,
  FaHashtag,
  FaClock,
  FaArrowUp,
  FaArrowDown,
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaRegClock,
  FaUndoAlt,
} from "react-icons/fa";
import styles from "./TransactionHistory.module.css";
import { getAllTranscations } from "../../services/transcation.service";

const TransactionTypes = {
  BID_PLACEMENT: {
    label: "Bid Placement",
    color: "#FF5733",
    icon: FaMoneyBillWave,
  },
  DEPOSIT: {
    label: "Debit",
    color: "#FF0000",
    icon: FaArrowUp,
  },
  CREDIT: {
    label: "Credit",
    color: "#28A745",
    icon: FaArrowDown,
  },
  WITHDRAWAL: {
    label: "Withdrawal",
    color: "#17A2B8",
    icon: FaArrowUp,
  },
  REFUND: {
    label: "Refund",
    color: "#FFC107",
    icon: FaUndoAlt,
  },
  WIN: {
    label: "Win",
    color: "#FFD700",
    icon: FaRegCheckCircle,
  },
  FAILED: {
    label: "Failed",
    color: "#DC3545",
    icon: FaRegTimesCircle,
  },
  PENDING: {
    label: "Pending",
    color: "#6C757D",
    icon: FaRegClock,
  },
};

const TransactionHistory = ({ isSidebarOpen }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const fetchAllTransactions = async () => {
    try {
      const response = await getAllTranscations();
      setTransactions(response.data);
    } catch (error) {
      console.error("Failed to fetch transactions", error);
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <section
      className={`${styles.transactionHistory} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <h2 className={styles.transactionTitle}>Transaction History</h2>
      <ul className={styles.transactionList}>
        {transactions.map((item, index) => {
          const typeKey = item.type.replace(/\s+/g, "_").toUpperCase(); // Ensures matching keys
          const transactionType = TransactionTypes[typeKey];

          if (!transactionType) {
            return null; // Skip if type is not recognized
          }

          const IconComponent = transactionType.icon;

          return (
            <li
              key={index}
              className={styles.transactionItem}
              style={{ borderColor: transactionType.color }}
            >
              <div className={styles.transactionHeader}>
                <div className={styles.transactionType}>
                  <IconComponent
                    className={styles.transactionIcon}
                    style={{ color: transactionType.color }}
                  />
                  <span style={{ color: transactionType.color }}>
                    {transactionType.label}
                  </span>
                </div>
                <div className={styles.transactionAmount}>
                  ₹{item.amount}
                </div>
              </div>
              <div className={styles.transactionDetails}>
                <p className={styles.transactionDetailsItem}>
                  <FaCalendarAlt
                    className={styles.transactionDetailsItemIcon}
                  />
                  <span>
                    {new Date(item.createdAt).toLocaleDateString()}
                  </span>
                </p>
                <p className={styles.transactionDetailsItem}>
                  <FaClock className={styles.transactionDetailsItemIcon} />
                  <span>{formatTime(item.createdAt)}</span>
                </p>
                <p className={styles.transactionDetailsItem}>
                  <FaHashtag className={styles.transactionDetailsItemIcon} />
                  <span>{item.transactionId}</span>
                </p>
              </div>
              {item.details?.game && (
                <p className={styles.transactionGame}>
                  <FaGamepad
                    className={styles.transactionDetailsItemIcon}
                    style={{ color: transactionType.color }}
                  />
                  <span>{item.details.game}</span>
                </p>
              )}
              <p className={styles.transactionDescription}>{item.remarks}</p>
              <div className={styles.transactionFooter}>
                <p className={styles.transactionFooterItem}>
                  Method: {item.details?.depositMethod || item.method}
                </p>
                <p className={styles.transactionFooterItem}>
                  Balance: ₹{item.remainingBalance}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default TransactionHistory;
