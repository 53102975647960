import { getToken } from "./token";
const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const getAllFunds = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/fund-history`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch fund history");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching fund history:", error.message);
    throw error;
  }
};

export { getAllFunds };
