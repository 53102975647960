import React from 'react';
import styles from './FeatureSection.module.css';
import { FaShieldAlt, FaClock, FaMobileAlt, FaGamepad, FaUserShield, FaMoneyBillWave, FaLock, FaGlobe, FaHeadset } from 'react-icons/fa';
import { SiPaytm, SiPhonepe, SiGooglepay } from 'react-icons/si';

const features = [
  {
    icon: <FaShieldAlt />,
    title: 'Secure Betting',
    description: 'Your transactions and bets are fully secured with end-to-end encryption, ensuring a safe betting experience.',
  },
  {
    icon: <FaClock />,
    title: 'Real-Time Results',
    description: 'Get instant updates and results on all Satta Matka games as they happen, with no delays.',
  },
  {
    icon: <FaMobileAlt />,
    title: 'Responsive Design',
    description: 'Play seamlessly across all devices with our fully responsive and mobile-friendly design.',
  },
  {
    icon: <FaGamepad />,
    title: 'Multiple Game Options',
    description: 'Choose from a wide range of games including Kalyan, Milan, and Rajdhani, catering to all players.',
  },
  {
    icon: <FaUserShield />,
    title: 'User-Friendly Interface',
    description: 'Enjoy a simple and intuitive interface designed to make your betting experience hassle-free.',
  },
  {
    icon: <FaMoneyBillWave />,
    title: 'Easy Withdrawals',
    description: 'Withdraw your winnings instantly and securely using multiple payment methods.',
  },
  {
    icon: <FaLock />,
    title: 'Advanced Security',
    description: 'State-of-the-art security measures, including two-factor authentication, to protect your account.',
  },
  {
    icon: (
      <div className={styles.multiIcon}>
        <SiPaytm /> <SiPhonepe /> <SiGooglepay />
      </div>
    ),
    title: 'Multiple Payment Options',
    description: 'Add funds via UPI (Paytm, PhonePe, Google Pay) or other popular payment methods securely and quickly.',
  },
  {
    icon: <FaGlobe />,
    title: 'Global Access',
    description: 'Access your favorite games from anywhere in the world with our global online platform.',
  },
  {
    icon: <FaHeadset />,
    title: '24/7 Support',
    description: 'Need help? Our support team is available 24/7 to assist you with any issues or inquiries.',
  },
];

const FeatureSection = () => {
  return (
    <section className={styles.featureSection}>
      <h2 className={styles.title}>Key Features</h2>
      <div className={styles.featuresGrid}>
        {features.map((feature, index) => (
          <div className={styles.featureCard} key={index}>
            <div className={styles.icon}>{feature.icon}</div>
            <h3 className={styles.featureTitle}>{feature.title}</h3>
            <p className={styles.description}>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeatureSection;
