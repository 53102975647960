import React from "react";

import PersonalProfile from "../components/profile/PersonalProfile";
import UPIDetails from "../components/profile/UPIDetails";
import BankAccountDetails from "../components/profile/BankAccountDetails";
import styles from "./ProfilePage.module.css";
const ProfilePage = ({ isSidebarOpen }) => {
  return (
    <div
      className={`${styles.profilePageContainer} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <PersonalProfile />
      <UPIDetails />
      <BankAccountDetails />
    </div>
  );
};

export default ProfilePage;
