import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Typography,
  Checkbox,
  Modal,
  message,
} from "antd";
import {
  IdcardOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styles from "./UPIDetails.module.css";
import {
  getAccountDetails,
  addUpiDetailsToCustomerAccount,
  updateUpiDetailsToCustomerAccount,
  deleteUpiDetailsFromCustomerAccount,
} from "../../services/account.service";

const { Title } = Typography;

const UpiDetails = () => {
  const [editableIndex, setEditableIndex] = useState(null);
  const [upiAccounts, setUpiAccounts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUpi, setCurrentUpi] = useState(null);

  useEffect(() => {
    fetchUpiDetails();
  }, []);

  const fetchUpiDetails = async () => {
    try {
      const response = await getAccountDetails();
      setUpiAccounts(response.data[0].upiDetails);
    } catch (error) {
      console.error("Failed to fetch UPI details:", error);
    }
  };

  const toggleEditable = (index) => {
    setEditableIndex(index === editableIndex ? null : index);
  };

  const handleDelete = async (upiId) => {
    try {
      await deleteUpiDetailsFromCustomerAccount(upiId);
      setUpiAccounts(upiAccounts.filter((upi) => upi._id !== upiId));
      message.success("UPI ID deleted successfully.");
    } catch (error) {
      console.error("Failed to delete UPI ID:", error);
      message.error("Failed to delete UPI ID.");
    }
  };

  const handleInputChange = (e, index, field) => {
    const newUpiAccounts = [...upiAccounts];
    newUpiAccounts[index][field] = e.target.value;
    setUpiAccounts(newUpiAccounts);
  };

  const handleCheckboxChange = (e, index) => {
    const newUpiAccounts = [...upiAccounts];
    newUpiAccounts[index].isPrimary = e.target.checked;
    setUpiAccounts(newUpiAccounts);
  };

  const handleAddNewUpi = () => {
    setCurrentUpi(null); // Clear current UPI for adding a new one
    setIsModalVisible(true); // Show modal
  };

  const handleEditUpi = (upi) => {
    setCurrentUpi(upi); // Set current UPI for editing
    setIsModalVisible(true); // Show modal
  };

  const handleModalOk = async (values) => {
    if (currentUpi) {
      // Edit existing UPI ID
      try {
        await updateUpiDetailsToCustomerAccount(currentUpi._id, values);
        fetchUpiDetails(); // Refresh the list
        message.success("UPI ID updated successfully.");
      } catch (error) {
        console.error("Failed to update UPI ID:", error);
        message.error("Failed to update UPI ID.");
      }
    } else {
      // Add new UPI ID
      try {
        await addUpiDetailsToCustomerAccount(values);
        fetchUpiDetails(); // Refresh the list
        message.success("UPI ID added successfully.");
      } catch (error) {
        console.error("Failed to add new UPI ID:", error);
        message.error("Failed to add new UPI ID.");
      }
    }
    setIsModalVisible(false); // Close modal
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <Title level={4} className={styles.sectionTitle}>
          UPI Account Details
        </Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddNewUpi}
          className={styles.addButton}
        >
          Add New UPI ID
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        {upiAccounts.map((upi, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card className={styles.infoCard}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <span className={styles.label}>
                      <IdcardOutlined className={styles.icon} /> UPI ID
                    </span>
                  }
                >
                  <Input
                    placeholder="Enter UPI ID"
                    disabled={editableIndex !== index}
                    value={upi.upiId}
                    onChange={(e) => handleInputChange(e, index, "upiId")}
                    className={styles.inputField}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={upi.isPrimary}
                    disabled={editableIndex !== index}
                    onChange={(e) => handleCheckboxChange(e, index)}
                  >
                    Set as Primary UPI ID
                  </Checkbox>
                </Form.Item>
              </Form>
              <div className={styles.buttonGroup}>
                <Button
                  type="primary"
                  onClick={() => handleEditUpi(upi)}
                  className={styles.editButton}
                >
                  Edit
                </Button>
                <Button
                  type="danger"
                  onClick={() => handleDelete(upi._id)}
                  className={styles.deleteButton}
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        title={currentUpi ? "Edit UPI ID" : "Add New UPI ID"}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={currentUpi || {}}
          onFinish={handleModalOk}
        >
          <Form.Item
            name="upiId"
            label="UPI ID"
            rules={[
              { required: true, message: "Please enter UPI ID" },
            ]}
          >
            <Input placeholder="Enter UPI ID" />
          </Form.Item>
          <Form.Item name="isPrimary" valuePropName="checked">
            <Checkbox>Set as Primary UPI ID</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentUpi ? "Update UPI ID" : "Add UPI ID"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpiDetails;
