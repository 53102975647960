import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";

const PublicRoutes = ({ isSidebarOpen }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<LandingPage isSidebarOpen={isSidebarOpen} />}
        />
      </Routes>
    </>
  );
};

export default PublicRoutes;
