import { getToken } from "./token";
const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const getUserBalance = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/account/balance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user balance");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user balance:", error.message);
    throw error;
  }
};

const getAccountDetails = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/account/details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch account details");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching account details:", error.message);
    throw error;
  }
};

const addUpiDetailsToCustomerAccount = async (upiData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/account/upi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(upiData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while adding upi details:", error.message);
    throw error;
  }
};
const updateUpiDetailsToCustomerAccount = async (upiID, updateData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/upi?upiID=${upiID}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(updateData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while updaing upi details:", error.message);
    throw error;
  }
};
const deleteUpiDetailsFromCustomerAccount = async (upiID) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/upi?upiID=${upiID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while deleting upi details:", error.message);
    throw error;
  }
};

const addBankDetailsToCustomerAccount = async (accountData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/bank-account`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(accountData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while adding account:", error.message);
    throw error;
  }
};
const updateBankDetailsToCustomerAccount = async (
  bankAccountId,
  updateData
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/bank-account?bankAccountId=${bankAccountId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(updateData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while adding account:", error.message);
    throw error;
  }
};
const deleteBankDetailsFromCustomerAccount = async (bankAccountId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/bank-account?bankAccountId=${bankAccountId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while deleting account:", error.message);
    throw error;
  }
};
const addFundRequest = async (fundData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/add-fund`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(fundData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while adding fund request:", error.message);
    throw error;
  }
};
const withdrawFundRequest = async (withdrawData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/account/withdraw-fund`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(withdrawData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while raising withdraw request:", error.message);
    throw error;
  }
};
export {
  getUserBalance,
  getAccountDetails,
  addUpiDetailsToCustomerAccount,
  updateUpiDetailsToCustomerAccount,
  deleteUpiDetailsFromCustomerAccount,
  addBankDetailsToCustomerAccount,
  updateBankDetailsToCustomerAccount,
  deleteBankDetailsFromCustomerAccount,
  addFundRequest,
  withdrawFundRequest
};
