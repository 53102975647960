import React, { useState } from "react";
import { Form, Input, Button, Typography, Row, Col } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  LockOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import styles from "./PersonalProfile.module.css";

const { Title } = Typography;

const PersonalProfile = () => {
  const [editable, setEditable] = useState(false);

  const profileData = {
    username: "johndoe",
    name: "John Doe",
    phone: "1234567890",
    email: "johndoe@example.com",
    password: "******",
  };

  const toggleEditable = () => {
    setEditable(!editable);
  };

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <Title level={4} className={styles.sectionTitle}>
          <UserOutlined className={styles.icon} /> Personal Information
        </Title>
        <Button
          type="link"
          className={styles.editButton}
          icon={editable ? <SaveOutlined /> : <EditOutlined />}
          onClick={toggleEditable}
        >
          {editable ? "Save" : "Edit"}
        </Button>
      </div>
      <Form layout="vertical" initialValues={profileData}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  <UserOutlined className={styles.icon} /> Username
                </span>
              }
              name="username"
            >
              <Input
                placeholder="Enter your username"
                disabled={!editable}
                className={styles.inputField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  <UserOutlined className={styles.icon} /> Name
                </span>
              }
              name="name"
            >
              <Input
                placeholder="Enter your full name"
                disabled={!editable}
                className={styles.inputField}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  <PhoneOutlined className={styles.icon} /> Phone Number
                </span>
              }
              name="phone"
            >
              <Input
                placeholder="Enter your phone number"
                disabled={!editable}
                className={styles.inputField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  <MailOutlined className={styles.icon} /> Email Address
                </span>
              }
              name="email"
            >
              <Input
                placeholder="Enter your email address"
                disabled={!editable}
                className={styles.inputField}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  <LockOutlined className={styles.icon} /> Password
                </span>
              }
              name="password"
            >
              <Input.Password
                placeholder="Enter your password"
                disabled={!editable}
                className={styles.inputField}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PersonalProfile;
