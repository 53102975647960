import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaClock,
  FaHashtag,
  FaCoins,
  FaTrophy,
} from "react-icons/fa";
import styles from "./BidHistory.module.css";
import { getAllBids } from "../../services/bid.service";

const BidHistory = ({ isSidebarOpen }) => {
  const [bids, setBids] = useState([]);

  useEffect(() => {
    fetchAllBids();
  }, []);

  const fetchAllBids = async () => {
    try {
      let response = await getAllBids();
      setBids(response.data);
    } catch (error) {
      console.error("Error fetching bids:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-IN", options).format(
      new Date(dateString)
    );
  };

  const formatTime = (dateString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Intl.DateTimeFormat("en-IN", options).format(
      new Date(dateString)
    );
  };

  return (
    <section
      className={`${styles.bidHistory} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      {bids.map((bid, index) => (
        <div key={index} className={styles.bidHistoryCard}>
          <div className={styles.cardHeader}>
            <span className={`${styles.label} ${styles.gameType}`}>
              {bid.betTypeName}
            </span>
            <span className={`${styles.label} ${styles.gameName}`}>
              {bid.marketName}
            </span>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.playDetails}>
              <p className={styles.playOn}>
                <FaCalendarAlt className={styles.icon} />
                <span className={styles.fieldLabel}>Play On:</span>
                <span className={styles.fieldValue}>
                  {formatDate(bid.bidPlacedOn)}
                </span>
              </p>
              <p className={styles.playFor}>
                <FaCalendarAlt className={styles.icon} />
                <span className={styles.fieldLabel}>Play For:</span>
                <span className={styles.fieldValue}>
                  {formatDate(bid.bidForDate)}
                </span>
              </p>
            </div>
            <div className={styles.digitPoints}>
              <p className={styles.digit}>
                <FaHashtag className={styles.icon} />
                <span className={styles.fieldLabel}>Digit:</span>
                <span className={styles.fieldValue}>{bid.digit}</span>
              </p>
              <p className={styles.points}>
                <FaCoins className={styles.icon} />
                <span className={styles.fieldLabel}>Points:</span>
                <span className={styles.fieldValue}>{bid.bidAmount}</span>
              </p>
            </div>
            <div className={styles.bidDetails}>
              <p className={styles.bidId}>
                <FaHashtag className={styles.icon} />
                <span className={styles.fieldLabel}>Bid ID:</span>
                <span className={styles.fieldValue}>{bid.bidId}</span>
              </p>
              <p className={styles.bidTime}>
                <FaClock className={styles.icon} />
                <span className={styles.fieldLabel}>Bid Time:</span>
                <span className={styles.fieldValue}>
                  {formatTime(bid.bidPlacedOn)}
                </span>
              </p>
            </div>
            <div className={styles.result}>
              <p className={styles.resultStatus}>
                <FaTrophy className={`${styles.icon} ${styles.resultIcon}`} />
                <span className={styles.fieldLabel}>Result:</span>
                <span className={styles.fieldValue}>{bid.winningStatus}</span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default BidHistory;
