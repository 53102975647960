import React, { useEffect, useState } from "react";
import { message, Alert } from "antd";
import { load } from "@cashfreepayments/cashfree-js";
import { FiLock, FiCreditCard } from "react-icons/fi";
import styles from "./CashfreePayment.module.css";
import { useLocation } from "react-router-dom";

const CashfreePayment = ({ isSidebarOpen }) => {
  const [cashfree, setCashfree] = useState(null);

  const location = useLocation();
  const { sessionId, amount } = location.state || {};

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cf = await load({
          mode: "sandbox",
        });
        setCashfree(cf);
      } catch (error) {
        console.error("Failed to load Cashfree SDK:", error);
        message.error(
          "Failed to initialize payment gateway. Please try again."
        );
      }
    };

    initializeSDK();
  }, []);

  const doPayment = async () => {
    if (!cashfree) {
      message.error("Payment gateway is not ready yet. Please wait a moment.");
      return;
    }

    const checkoutOptions = {
      paymentSessionId: sessionId,
      redirectTarget: "_self",
    };

    try {
      const response = await cashfree.checkout(checkoutOptions);

      if (response && response.status === "SUCCESS") {
        message.success("Payment was successful!");
      } else {
        // message.error("Payment failed. Please try again.");
      }
    } catch (error) {
      message.error(
        "An error occurred during the payment process. Please try again."
      );
    }
  };

  return (
    <div
      className={`${styles.paymentContainer} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <div className={styles.paymentSummary}>
        <h2 className={styles.title}>Review and Confirm Payment</h2>
        <div className={styles.amountContainer}>
          <FiCreditCard className={styles.icon} />
          <span className={styles.amountLabel}>Amount to Pay:</span>
          <span className={styles.amount}>₹{amount}</span>
        </div>
        <p className={styles.note}>
          Please confirm the amount before proceeding. Ensure that your payment
          details are correct.
        </p>
        <Alert
          message="Security Information"
          description="Your payment is securely processed with 256-bit encryption to ensure your transaction is safe."
          type="info"
          showIcon
          icon={<FiLock />}
          className={styles.securityAlert}
        />
      </div>
      <button
        type="submit"
        className={styles.payButton}
        onClick={doPayment}
        disabled={!cashfree}
      >
        Pay Now
      </button>
    </div>
  );
};

export default CashfreePayment;
