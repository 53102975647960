import React, { useState, useEffect } from "react";
import { FaSignOutAlt, FaWallet } from "react-icons/fa";
import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn } from "../../../store/authSlice";
import { useNavigate } from "react-router-dom";

import { fetchUserBalance } from "../../../store/balanceSlice"; // Import the thunk action

const Header = ({ isOpen }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch the balance and loading state from Redux
  const balance = useSelector((state) => state.balance.value);
  const balanceStatus = useSelector((state) => state.balance.status);

  // Mock balance (replace this with actual state or API call)
  const userBalance = "₹ 15,000";

  const texts = [
    "Your Most Trusted Betting Partner",
    "Experience the Best Betting",
    "Join Us for Daily Excitement",
    "Secure and Reliable Betting",
    "Bet with Confidence",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserBalance());
    }
  }, [dispatch, isLoggedIn]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div
      className={`${styles.header} ${
        isOpen ? styles.headerOpen : styles.headerClosed
      }`}
    >
      <div className={styles.textContainer}>
        <div className={`${styles.trustedText} ${styles.fadeInOut}`}>
          {texts[currentTextIndex]}
        </div>
      </div>
      <div className={styles.actions}>
        {isLoggedIn && (
          <>
            <div className={styles.walletContainer}>
              <FaWallet className={styles.walletIcon} />
              <span className={styles.walletBalance}>
                {/* Show loading state or balance */}
                {balanceStatus === "loading" ? "Loading..." : `₹ ${balance}`}
              </span>{" "}
            </div>
            <button className={styles.logoutButton} onClick={handleLogout}>
              <FaSignOutAlt className={styles.logoutIcon} />
              Logout
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
