import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../services/auth.service"; // Import the loginUser function from your authentication service
import toast, { Toaster } from "react-hot-toast";
import { notification } from "antd";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: localStorage.getItem("token") ? true : false,
    user: null,
    loading: false, // Add loading state
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      const { token } = action.payload;
      localStorage.setItem("token", token);
      state.isLoggedIn = true;
      state.loading = false;
    },
    loginFailure: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem("token");
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
  authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectCurrentUser = (state) => state.auth.user;

// Toast function to handle different types of notifications
const notify = (type, message) => {
  switch (type) {
    case "success":
      toast.success(message, {
        style: {
          border: "1px solid #4caf50",
          padding: "16px",
          color: "#4caf50",
          marginTop: "180px",
          marginLeft: "55px",
        },
        iconTheme: {
          primary: "#4caf50",
          secondary: "#FFFAEE",
        },
      });
      break;
    case "error":
      toast.error(message, {
        style: {
          border: "1px solid #f44336",
          padding: "16px",
          color: "#f44336",
          marginTop: "180px",
          marginLeft: "55px",
        },
        iconTheme: {
          primary: "#f44336",
          secondary: "#FFFAEE",
        },
      });
      break;
    case "info":
      toast(message, {
        icon: "ℹ️",
        style: {
          border: "1px solid #2196f3",
          padding: "16px",
          color: "#2196f3",
          marginTop: "180px",
          marginLeft: "55px",
        },
      });
      break;
    default:
      toast(message);
  }
};

// Asynchronous thunk action creator
export const loginUserAsync = (phoneNumber, password,onClose) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await loginUser(phoneNumber, password);
    if (response.status == 200) {
      notification.success({
        message: "Login Successful",
        description: `Welcome back, ${response.data.data.account.fullName}!`,
      });
      console.log("response",response)
      dispatch(loginSuccess({ token: response.data.data.tokens.access.token }));
      onClose();
      // navigate("/dashboard"); // Redirect to the dashboard on successful login
    } else if (response.status == 400) {
      // Handle login failure with a message from the API response
      notification.error({
        message: "Login Failed",
        description:
          response.response.data.message ||
          "Please check your credentials and try again.",
      });
    }
    // if (response.statusCode === 404) {
    //   notify("error", `Login failed: ${response.message}.`);
    // }
    // if (response.statusCode === 400) {
    //   notify("error", `Login failed: ${response.message}.`);
    // }
    // if (response.statusCode === 200) {
    //   notify("success", "Logged in successfully!");
    //   dispatch(loginSuccess({ token: response.tokens.access.token }));
    // }
  } catch (error) {
    notify("error", `Login failed: ${error.message}.`);
    dispatch(loginFailure());
  }
};

export default authSlice.reducer;
