import axios from "axios";

const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const loginUser = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/customer/auth/login`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};

const signupUser = async (userData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while registering  user:", error.message);
    throw error;
  }
};

export { loginUser, signupUser };
