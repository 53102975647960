import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    FaHome,
    FaHistory,
    FaMoneyBillAlt,
    FaTrophy,
    FaBell,
    FaBars,
    FaTimes,
    FaChartLine,
    FaRegChartBar,
    FaCog,
    FaUser,
    FaSignOutAlt,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { logout } from '../../../store/authSlice';  // Adjust the import path as necessary
import styles from './Sidebar.module.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <>
            <button className={`${styles.toggleButton} ${isOpen ? styles.closeButton : ''}`} onClick={toggleSidebar}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </button>

            <div className={`${styles.sidebar} ${isOpen ? styles.sidebarOpen : styles.sidebarClosed}`}>
                <div className={styles.sidebarHeader}>
                    <span className={styles.logo}>BetMaster</span>
                </div>
                <div className={styles.menu}>
                    <Link to="/home" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaHome className={styles.icon} />
                        {isOpen && <span>Home</span>}
                    </Link>
                    <Link to="/transactions" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaHistory className={styles.icon} />
                        {isOpen && <span>Transactions</span>}
                    </Link>
                    <Link to="/funds" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaMoneyBillAlt className={styles.icon} />
                        {isOpen && <span>Funds</span>}
                    </Link>
                    <Link to="/top-winners" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaTrophy className={styles.icon} />
                        {isOpen && <span>Top Winners</span>}
                    </Link>
                    <Link to="/notifications" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaBell className={styles.icon} />
                        {isOpen && <span>Notifications</span>}
                    </Link>
                    <Link to="/bid-history" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaChartLine className={styles.icon} />
                        {isOpen && <span>Bid History</span>}
                    </Link>
                    <Link to="/statistics" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaRegChartBar className={styles.icon} />
                        {isOpen && <span>Statistics</span>}
                    </Link>
                    <Link to="/settings" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaCog className={styles.icon} />
                        {isOpen && <span>Settings</span>}
                    </Link>
                    <Link to="/profile" className={styles.menuItem} onClick={toggleSidebar}>
                        <FaUser className={styles.icon} />
                        {isOpen && <span>Profile</span>}
                    </Link>
                    <Link to="#" className={styles.menuItem} onClick={() => { toggleSidebar(); handleLogout(); }}>
                        <FaSignOutAlt className={styles.icon} />
                        {isOpen && <span>Logout</span>}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
