import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./GamesList.module.css";
import { getAllMarkets } from "../../services/market.service";
import { selectIsLoggedIn } from "../../store/authSlice";
import { notification } from "antd";

const GamesList = ({ isSidebarOpen }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Handle the Play Game button click, including market details as URL parameters
  const handlePlayClick = (market) => {
    if (!isLoggedIn) {
      notification.error({
        message: "Login Required",
        description:
          "You need to be logged in to play the game. Please log in to continue.",
      });
    } else {
      setSelectedGame(market);
      // Navigate to /play with marketName and mid as query parameters
      navigate(
        `/play?market=${encodeURIComponent(market.marketName)}&mid=${
          market._id
        }`
      );
    }
  };

  useEffect(() => {
    fetchAllMarkets();
    const interval = setInterval(fetchAllMarkets, 60000); // Refresh every minute
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const fetchAllMarkets = async () => {
    setLoading(true); // Show loader when fetching data
    try {
      const response = await getAllMarkets();
      setMarkets(response.data);
    } catch (error) {
      console.error("Error fetching markets:", error);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  return (
    <div
      className={`${styles.gamesList} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <h2 className={styles.title}>Best Satta Matka Game</h2>

      {loading && (
        <div className={styles.loaderContainer}>
          <div className={styles.beatLoader}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <div
        className={`${styles.grid} ${
          isSidebarOpen ? styles.gridOpen : styles.gridClosed
        }`}
      >
        {markets.map((market, index) => (
          <div
            className={styles.gameCard}
            key={index}
            style={{ color: "#FFD700" }}
          >
            <div className={styles.cardContent}>
              <div className={styles.timeDetails}>
                <div className={styles.openTime}>
                  Open: {market.timings.openingTime}
                </div>
                <div className={styles.closeTime}>
                  Close: {market.timings.closingTime}
                </div>
              </div>
              <div className={styles.details}>
                <h3 className={styles.gameName}>{market.marketName}</h3>
                <p
                  className={`${styles.status} ${
                    styles[
                      market.marketStatus.replace(/\s+/g, "").toLowerCase()
                    ]
                  }`}
                >
                  {market.marketStatus}
                </p>
                <p className={styles.result}>
                  {market.openPanna !== null ? market.openPanna : "***"}_
                  {market.openDigit === null && market.closeDigit === null
                    ? "**"
                    : market.openDigit !== null && market.closeDigit === null
                    ? `${market.openDigit}*`
                    : `${market.openDigit}${market.closeDigit}`}
                  _{market.closePanna !== null ? market.closePanna : "***"}
                </p>
              </div>
              <button
                className={styles.playButton}
                onClick={() => handlePlayClick(market)}
                disabled={
                  market.marketStatus.toLowerCase() === "closed for today"
                }
                title={
                  market.marketStatus.toLowerCase() === "closed for today"
                    ? "Market is closed for today"
                    : "Play Game"
                }
              >
                Play Game
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GamesList;
