import React from 'react';
import { Table, Typography, Card } from 'antd';
import { DollarOutlined, CalendarOutlined, TagOutlined, UserOutlined } from '@ant-design/icons';
import styles from './TopWinners.module.css';

const { Title } = Typography;

const obscureName = (name) => {
  const parts = name.split(" ");
  return parts.map(part => part.length > 2 ? `${part[0]}xx${part.slice(-1)}` : part).join(" ");
};

const winnersData = [
  {
    gameName: "SRIDEVI MORNING",
    customerName: "John Doe",
    wonAmount: "₹50,000",
    digit: "12345",
    dateTime: "2024-08-11 09:30 AM",
    textColor: "#FF6347", // Tomato
  },
  {
    gameName: "KALYAN DAY",
    customerName: "Jane Smith",
    wonAmount: "₹75,000",
    digit: "67890",
    dateTime: "2024-08-11 10:00 AM",
    textColor: "#32CD32", // LimeGreen
  },
  {
    gameName: "MILAN NIGHT",
    customerName: "Robert Johnson",
    wonAmount: "₹100,000",
    digit: "54321",
    dateTime: "2024-08-11 11:00 AM",
    textColor: "#1E90FF", // DodgerBlue
  },
];

const columns = [
  {
    title: <span className={styles.columnHeader}>Game</span>,
    dataIndex: 'gameName',
    key: 'gameName',
    render: (text, record) => (
      <span className={styles.gameName} style={{ color: record.textColor }}>{text}</span>
    ),
  },
  {
    title: <span className={styles.columnHeader}>Customer</span>,
    dataIndex: 'customerName',
    key: 'customerName',
    render: (text) => (
      <span className={styles.customerName}><UserOutlined className={styles.icon} /> {obscureName(text)}</span>
    ),
  },
  {
    title: <span className={styles.columnHeader}>Amount</span>,
    dataIndex: 'wonAmount',
    key: 'wonAmount',
    render: (text) => (
      <span className={styles.wonAmount}><DollarOutlined className={styles.icon} /> {text}</span>
    ),
  },
  {
    title: <span className={styles.columnHeader}>Digit</span>,
    dataIndex: 'digit',
    key: 'digit',
    render: (text) => (
      <span className={styles.digit}><TagOutlined className={styles.icon} /> {text}</span>
    ),
  },
  {
    title: <span className={styles.columnHeader}>Date & Time</span>,
    dataIndex: 'dateTime',
    key: 'dateTime',
    render: (text) => (
      <span className={styles.dateTime}><CalendarOutlined className={styles.icon} /> {text}</span>
    ),
  },
];

const renderCards = (data) => {
  return data.map((winner, index) => (
    <Card key={index} className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <span className={styles.cardGameName} style={{ color: winner.textColor }}>
            {winner.gameName}
          </span>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.cardDetail}>
            <UserOutlined className={styles.icon} />
            <span className={styles.cardLabel}>Customer:</span>
            <span className={styles.cardValue}>{obscureName(winner.customerName)}</span>
          </div>
          <div className={styles.cardDetail}>
            <DollarOutlined className={styles.icon} />
            <span className={styles.cardLabel}>Won Amount:</span>
            <span className={styles.cardValue}>{winner.wonAmount}</span>
          </div>
          <div className={styles.cardDetail}>
            <TagOutlined className={styles.icon} />
            <span className={styles.cardLabel}>Winning Digit:</span>
            <span className={styles.cardValue}>{winner.digit}</span>
          </div>
          <div className={styles.cardDetail}>
            <CalendarOutlined className={styles.icon} />
            <span className={styles.cardLabel}>Date & Time:</span>
            <span className={styles.cardValue}>{winner.dateTime}</span>
          </div>
        </div>
      </div>
    </Card>
  ));
};

const TopWinners = ({ isSidebarOpen }) => {
  return (
    <div
      className={`${styles.topWinners} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <Title level={2} className={styles.title}>Top Winners of Today</Title>

      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={winnersData}
          rowKey="gameName"
          pagination={false}
          className={styles.table}
        />
      </div>

      <div className={styles.cardsContainer}>
        {renderCards(winnersData)}
      </div>
    </div>
  );
};

export default TopWinners;
