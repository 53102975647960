import { getToken } from "./token";

const API_BASE_URL = "https://matka.travelmyst.com/api/v1";

const getBetTypes = async (mid) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/bet-type?mid=${mid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch bet types");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching bet types:", error.message);
    throw error;
  }
};

export { getBetTypes };
