import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./SignupPopup.module.css";
import LoginPopup from "./LoginPopup"; // Import LoginPopup
import { signupUser } from "../../services/auth.service"; // Import the API service

const SignupPopup = ({ onClose }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [form] = Form.useForm(); // Create a form instance

  const onFinish = async (values) => {
    const userData = {
      fullName: values.name,
      username: values.username,
      phoneNumber: values.phone,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    try {
      await signupUser(userData);
      notification.success({
        message: "Signup Successful",
        description: `Welcome, ${values.username}!`,
      });
      onClose();
    } catch (error) {
      notification.error({
        message: "Signup Failed",
        description: error.message,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "Signup Failed",
      description: "Please check your inputs and try again.",
    });
  };

  // Toggle to show Login
  const handleSwitchToLogin = () => {
    setShowLogin(true);
    form.resetFields(); // Clear input fields when switching
  };

  // Toggle to show Signup
  const handleSwitchToSignup = () => {
    setShowLogin(false);
    form.resetFields(); // Clear input fields when switching
  };

  // Show LoginPopup if showLogin is true
  if (showLogin) {
    return <LoginPopup onClose={onClose} onSwitchToSignup={handleSwitchToSignup} />;
  }

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <CloseOutlined />
        </button>
        <h2 className={styles.title}>Sign Up</h2>
        <Form
          name="signup"
          form={form} // Bind the form instance
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={styles.form}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter your name!" }]}
          >
            <Input placeholder="Name" className={styles.input} />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please enter your username!" }]}
          >
            <Input placeholder="Username" className={styles.input} />
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please enter your phone number!" },
              {
                pattern: /^\d{10}$/,
                message: "Please enter a valid phone number!",
              },
            ]}
          >
            <Input placeholder="Phone Number" className={styles.input} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password placeholder="Password" className={styles.input} />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              className={styles.input}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitButton}
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
        <p className={styles.switchText}>
          Already have an account?{" "}
          <span onClick={handleSwitchToLogin}>Login</span>
        </p>
      </div>
    </div>
  );
};

export default SignupPopup;
