import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Typography,
  Checkbox,
  Modal,
  message,
} from "antd";
import {
  IdcardOutlined,
  BankOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styles from "./BankAccountDetails.module.css";
import {
  getAccountDetails,
  addBankDetailsToCustomerAccount,
  updateBankDetailsToCustomerAccount,
  deleteBankDetailsFromCustomerAccount,
} from "../../services/account.service";

const { Title } = Typography;

const BankAccountDetails = () => {
  const [editableIndex, setEditableIndex] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const fetchAccountDetails = async () => {
    try {
      const response = await getAccountDetails();
      setAccounts(response.data[0].bankAccounts);
    } catch (error) {
      console.error("Failed to fetch account details:", error);
    }
  };

  const toggleEditable = (index) => {
    setEditableIndex(index === editableIndex ? null : index);
  };

  const handleDelete = async (accountId) => {
    try {
      await deleteBankDetailsFromCustomerAccount(accountId);
      setAccounts(accounts.filter((account) => account._id !== accountId));
      message.success("Account deleted successfully.");
    } catch (error) {
      console.error("Failed to delete account:", error);
      message.error("Failed to delete account.");
    }
  };

  const handleInputChange = (e, index, field) => {
    const newAccounts = [...accounts];
    newAccounts[index][field] = e.target.value;
    setAccounts(newAccounts);
  };

  const handleCheckboxChange = (e, index) => {
    const newAccounts = [...accounts];
    newAccounts[index].isPrimary = e.target.checked;
    setAccounts(newAccounts);
  };

  const handleAddNewAccount = () => {
    setCurrentAccount(null); // Clear current account for adding a new one
    setIsModalVisible(true); // Show modal
  };

  const handleEditAccount = (account) => {
    setCurrentAccount(account); // Set current account for editing
    setIsModalVisible(true); // Show modal
  };

  const handleModalOk = async (values) => {
    if (currentAccount) {
      // Edit existing account
      try {
        await updateBankDetailsToCustomerAccount(currentAccount._id, values);
        fetchAccountDetails(); // Refresh the list
        message.success("Account updated successfully.");
      } catch (error) {
        console.error("Failed to update account:", error);
        message.error("Failed to update account.");
      }
    } else {
      // Add new account
      try {
        await addBankDetailsToCustomerAccount(values);
        fetchAccountDetails(); // Refresh the list
        message.success("Account added successfully.");
      } catch (error) {
        console.error("Failed to add new account:", error);
        message.error("Failed to add new account.");
      }
    }
    setIsModalVisible(false); // Close modal
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <Title level={4} className={styles.sectionTitle}>
          Bank Account Details
        </Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddNewAccount}
          className={styles.addButton}
        >
          Add New Account
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        {accounts.map((account, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card className={styles.infoCard}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <span className={styles.label}>
                      <IdcardOutlined className={styles.icon} /> Account Holder
                      Name
                    </span>
                  }
                >
                  <Input
                    placeholder="Enter account holder name"
                    disabled={editableIndex !== index}
                    value={account.accountHolderName}
                    onChange={(e) =>
                      handleInputChange(e, index, "accountHolderName")
                    }
                    className={styles.inputField}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      <BankOutlined className={styles.icon} /> Bank Account
                      Number
                    </span>
                  }
                >
                  <Input
                    placeholder="Enter bank account number"
                    disabled={editableIndex !== index}
                    value={account.accountNumber}
                    onChange={(e) =>
                      handleInputChange(e, index, "accountNumber")
                    }
                    className={styles.inputField}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      <BankOutlined className={styles.icon} /> IFSC Code
                    </span>
                  }
                >
                  <Input
                    placeholder="Enter IFSC code"
                    disabled={editableIndex !== index}
                    value={account.ifscCode}
                    onChange={(e) => handleInputChange(e, index, "ifscCode")}
                    className={styles.inputField}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={account.isPrimary}
                    disabled={editableIndex !== index}
                    onChange={(e) => handleCheckboxChange(e, index)}
                  >
                    Set as Primary Account
                  </Checkbox>
                </Form.Item>
              </Form>
              <div className={styles.buttonGroup}>
                <Button
                  type="primary"
                  onClick={() => handleEditAccount(account)}
                  className={styles.editButton}
                >
                  Edit
                </Button>
                <Button
                  type="danger"
                  onClick={() => handleDelete(account._id)}
                  className={styles.deleteButton}
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        title={currentAccount ? "Edit Bank Account" : "Add New Bank Account"}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={currentAccount || {}}
          onFinish={handleModalOk}
        >
          <Form.Item
            name="accountHolderName"
            label="Account Holder Name"
            rules={[
              { required: true, message: "Please enter account holder name" },
            ]}
          >
            <Input placeholder="Enter account holder name" />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            label="Bank Account Number"
            rules={[
              { required: true, message: "Please enter bank account number" },
            ]}
          >
            <Input placeholder="Enter bank account number" />
          </Form.Item>
          <Form.Item
            name="ifscCode"
            label="IFSC Code"
            rules={[{ required: true, message: "Please enter IFSC code" }]}
          >
            <Input placeholder="Enter IFSC code" />
          </Form.Item>
          <Form.Item name="isPrimary" valuePropName="checked">
            <Checkbox>Set as Primary Account</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentAccount ? "Update Account" : "Add Account"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BankAccountDetails;
