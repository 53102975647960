import React from "react";
import Header from "../components/LandingPage/Header";
import HeroSection from "../components/LandingPage/HeroSection";
import GamesList from "../components/LandingPage/GamesListSection";
import FeatureSection from "../components/LandingPage/FeatureSection";
import PromotionSection from "../components/LandingPage/PromotionalSection";
import TestimonialSection from "../components/LandingPage/TestimonialsSection";
import Footer from "../components/LandingPage/Footer";
const LandingPage = () => {
  return (
    <div>
      <Header />
      {/* <HeroSection/> */}
      <GamesList />
      <FeatureSection />
      <PromotionSection />
      <TestimonialSection />
      <Footer/>
    </div>
  );
};

export default LandingPage;
