import React, { useState } from "react";
import styles from "./Header.module.css";
import LoginPopup from "./LoginPopup";
import SignupPopup from "./SignupPopup";

const Header = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  const gameNames = [
    "Single",
    "Jodi",
    "Patti",
    "Half Sangam",
    "Full Sangam",
    "King",
    "Queen",
  ];

  const openLogin = () => setIsLoginOpen(true);
  const closeLogin = () => setIsLoginOpen(false);

  const openSignup = () => setIsSignupOpen(true);
  const closeSignup = () => setIsSignupOpen(false);

  return (
    <header className={styles.header}>
      <div className={styles.marqueeContainer}>
        <marquee className={styles.marquee}>{gameNames.join(" • ")}</marquee>
      </div>
      <div className={styles.authButtons}>
        <button className={styles.loginButton} onClick={openLogin}>
          Login
        </button>
        <button className={styles.signupButton} onClick={openSignup}>
          Sign Up
        </button>
      </div>

      {isLoginOpen && <LoginPopup onClose={closeLogin} />}
      {isSignupOpen && <SignupPopup onClose={closeSignup} />}
    </header>
  );
};

export default Header;
