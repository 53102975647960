import React, { useEffect, useState } from "react";
import { Table, Button, Select, DatePicker, Input, Space } from "antd";
import {
  FiArrowDownCircle,
  FiArrowUpCircle,
  FiCreditCard,
  FiCheckCircle,
  FiXCircle,
} from "react-icons/fi";
import styles from "./FundHistory.module.css";
import { useNavigate } from "react-router-dom";
import { getAllFunds } from "../../services/fund.service";
import WithdrawModal from "./WithdrawModal"; // Import WithdrawModal component

const { RangePicker } = DatePicker;
const { Option } = Select;

const FundHistory = ({ isSidebarOpen }) => {
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false); // State to manage WithdrawModal visibility
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  const fetchTransactionHistory = async () => {
    try {
      const response = await getAllFunds(); // Fetch transaction history
      setTransactions(response.data);
      setFilteredTransactions(response.data); // Initialize with full data set
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const navigate = useNavigate();

  const handleFilterChange = (value, type) => {
    let filteredData = transactions;

    if (type === "type" && value) {
      filteredData = filteredData.filter(
        (transaction) => transaction.type.toLowerCase() === value.toLowerCase()
      );
    }

    if (type === "status" && value) {
      filteredData = filteredData.filter(
        (transaction) =>
          transaction.status.toLowerCase() === value.toLowerCase()
      );
    }

    if (type === "dateRange" && value) {
      filteredData = filteredData.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        return transactionDate >= value[0] && transactionDate <= value[1];
      });
    }

    if (type === "amountRange" && value) {
      filteredData = filteredData.filter(
        (transaction) =>
          (value[0] === undefined || transaction.amount >= value[0]) &&
          (value[1] === undefined || transaction.amount <= value[1])
      );
    }

    setFilteredTransactions(filteredData);
  };

  const renderTransactionCard = (transaction) => (
    <div className={styles.transactionCard} key={transaction._id}>
      <div className={styles.transactionCardHeader}>
        <span>{transaction.transactionId}</span>
        <span>{new Date(transaction.createdAt).toLocaleDateString()}</span>
      </div>
      <div className={styles.transactionCardDetail}>{transaction.remarks}</div>
      <div className={styles.transactionCardDetail}>
        ₹{transaction.amount.toFixed(2)}
      </div>
      <div className={styles.transactionCardDetail}>
        <span
          className={
            transaction.type === "Credit" ? styles.credit : styles.debit
          }
        >
          {transaction.type === "Credit" ? (
            <FiArrowDownCircle />
          ) : (
            <FiArrowUpCircle />
          )}{" "}
          {transaction.type}
        </span>
      </div>
      <div className={styles.transactionCardDetail}>
        <span className={styles[transaction.status.toLowerCase()]}>
          {transaction.status === "Completed" ? (
            <FiCheckCircle />
          ) : transaction.status === "Failed" ? (
            <FiXCircle />
          ) : (
            <FiCreditCard />
          )}
          {transaction.status}
        </span>
      </div>
    </div>
  );

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `₹${amount.toFixed(2)}`,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <span
          className={
            type.toLowerCase() === "credit" ? styles.credit : styles.debit
          }
        >
          {type.toLowerCase() === "credit" ? (
            <FiArrowDownCircle />
          ) : (
            <FiArrowUpCircle />
          )}{" "}
          {type}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span className={styles[status.toLowerCase()]}>
          {status === "Completed" ? (
            <FiCheckCircle />
          ) : status === "Failed" ? (
            <FiXCircle />
          ) : (
            <FiCreditCard />
          )}
          {status}
        </span>
      ),
    },
  ];

  const openWithdrawModal = () => {
    setWithdrawModalVisible(true); // Open Withdraw Modal
  };

  const closeWithdrawModal = () => {
    setWithdrawModalVisible(false); // Close Withdraw Modal
  };

  const handleWithdrawSubmit = (values) => {
    console.log("Withdraw submitted:", values);
    // Implement the logic for withdraw submission here
  };

  return (
    <div
      className={`${styles.fundHistoryContainer} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>Fund History</h2>
        <Space>
          <Button
            type="primary"
            icon={<FiArrowDownCircle />}
            className={styles.addButton}
            onClick={() => navigate("/add-fund")}
          >
            Add Fund
          </Button>
          <Button
            type="danger"
            icon={<FiArrowUpCircle />}
            className={styles.withdrawButton}
            onClick={openWithdrawModal} // Open modal on button click
          >
            Withdraw
          </Button>
        </Space>
      </div>
      <div className={styles.filters}>
        <Select
          placeholder="Select Type"
          onChange={(value) => handleFilterChange(value, "type")}
          allowClear
        >
          <Option value="Credit">Credit</Option>
          <Option value="Debit">Debit</Option>
        </Select>
        <Select
          placeholder="Select Status"
          onChange={(value) => handleFilterChange(value, "status")}
          allowClear
        >
          <Option value="Completed">Completed</Option>
          <Option value="Failed">Failed</Option>
          <Option value="Pending">Pending</Option>
        </Select>
        <RangePicker
          onChange={(value) => handleFilterChange(value, "dateRange")}
          placeholder={["Start Date", "End Date"]}
        />
        <Input
          type="number"
          placeholder="Min Amount"
          onChange={(e) =>
            handleFilterChange(
              [Number(e.target.value), undefined],
              "amountRange"
            )
          }
        />
        <Input
          type="number"
          placeholder="Max Amount"
          onChange={(e) =>
            handleFilterChange(
              [undefined, Number(e.target.value)],
              "amountRange"
            )
          }
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredTransactions}
        rowKey={(record) => record._id}
        className={styles.transactionsTable}
        pagination={{ pageSize: 5 }}
      />
      {filteredTransactions.map(renderTransactionCard)}

      {/* Withdraw Modal */}
      <WithdrawModal
        visible={isWithdrawModalVisible}
        onClose={closeWithdrawModal}
        onSubmit={handleWithdrawSubmit}
      />
    </div>
  );
};

export default FundHistory;
