import React from 'react';
import styles from './TestimonialSection.module.css';
import { FaStar } from 'react-icons/fa';

const testimonials = [
  {
    name: 'Ravi Kumar',
    position: 'Frequent Player',
    feedback: 'Amazing experience! The games are thrilling, and the support team is always there to help. Highly recommend!',
    rating: 5,
  },
  {
    name: 'गिराज मीना',
    position: 'नया उपयोगकर्ता',
    feedback: 'मैंने इसमें इतना मज़ा लिया कि 2-4 बार 50-50 हजार जीत चुका हूं। आप भी खेलिए, शायद अगली बार मैं ही आपके पैसे जीत लूं।',
    rating: 5,
  },
  {
    name: 'Ankit Patel',
    position: 'Loyal Member',
    feedback: 'The promotions and bonuses are fantastic. I’ve had great luck with the games and appreciate the regular updates.',
    rating: 5,
  },
  {
    name: 'Sneha Verma',
    position: 'Regular Player',
    feedback: 'एक शीर्ष-स्तरीय प्लेटफार्म जिसमें बेहतरीन फीचर्स और सुचारू गेमप्ले है। अत्यधिक सिफारिश!',
    rating: 5,
  },
  {
    name: 'Vikas Gupta',
    position: 'Occasional Player',
    feedback: 'I enjoy playing casually, and this platform offers exactly what I need. The bonuses are a great touch!',
    rating: 4,
  },
];

const TestimonialSection = () => {
  return (
    <section className={styles.testimonialSection}>
      <h2 className={styles.title}>What Our Users Say</h2>
      <div className={styles.testimonialsWrapper}>
        <div className={styles.testimonialsMarquee}>
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div className={styles.testimonialCard} key={index}>
              <p className={styles.feedback}>“{testimonial.feedback}”</p>
              <div className={styles.userInfo}>
                <h3 className={styles.userName}>{testimonial.name}</h3>
                <p className={styles.userPosition}>{testimonial.position}</p>
                <div className={styles.rating}>
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <FaStar key={i} className={styles.star} />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
