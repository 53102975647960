import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./PlayGame.module.css";
import singleDigitIcon from "../../assets/jodi.jpg";
import jodiDigitIcon from "../../assets/jodi.jpg";
import singlePannaIcon from "../../assets/ekka.jpg";
import doublePannaIcon from "../../assets/doubleekka.jpeg";
import triplePannaIcon from "../../assets/trippleekka.png";
import { getBetTypes } from "../../services/betType.service";
const PlayGame = ({ isSidebarOpen }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const marketName = searchParams.get("market");
  const mid = searchParams.get("mid");

  const [betTypes, setBetTypes] = useState([]);

  const iconsMap = {
    "Single Ank": singleDigitIcon,
    "Jodi": jodiDigitIcon,
    "Single Patti": singlePannaIcon,
    "Double Patti": doublePannaIcon,
    "Tripple Patti": triplePannaIcon,
  };

  useEffect(() => {
    fetchBetTypes(mid);
  }, [mid]);

  const fetchBetTypes = async (mid) => {
    try {
      const response = await getBetTypes(mid);
      setBetTypes(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    // Simulate loading for 1 second
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  // Handler for navigating to the Bid Placement Form
  const handleGameClick = (item) => {
    navigate(
      `/bid-placement?market=${encodeURIComponent(marketName)}&mid=${mid}`,
      { state: { item } }
    );
  };

  return (
    <div
      className={`${styles.playGame} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <h2 className={styles.title}>Choose Your Bet</h2>
      <h3 className={styles.marketInfo}>Market: {marketName}</h3>
      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.beatLoader}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className={styles.options}>
          {betTypes.map((item, index) => (
            <div
              className={styles.option}
              key={item._id}
              onClick={() => handleGameClick(item)}
            >
              <div className={styles.cardHeader}>
                <span className={styles.rate}>10 KA {item.rate}</span>
                <span className={styles.multiplier}>{item.multiplier}</span>
              </div>
              <img
                src={iconsMap[item.betName] || singleDigitIcon} // Fallback icon
                alt={item.betName}
                className={styles.icon}
              />
              <h3>{item.betName}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayGame;
