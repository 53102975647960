import React from 'react';
import styles from './PromotionSection.module.css';
import { FaGift, FaRocket, FaPercentage, FaCoins, FaClock } from 'react-icons/fa';

const promotions = [
  {
    icon: <FaGift />,
    title: 'Welcome Bonus',
    description: 'Get a 100% bonus on your first deposit. Start your journey with double the chances!',
  },
  {
    icon: <FaRocket />,
    title: 'Daily Speedy Draws',
    description: 'Join daily speedy draws and win big every day. Excitement never stops!',
  },
  {
    icon: <FaPercentage />,
    title: 'Cashback Offers',
    description: 'Enjoy up to 20% cashback on your weekly losses. Play more, worry less!',
  },
  {
    icon: <FaCoins />,
    title: 'Loyalty Rewards',
    description: 'Earn points on every bet and redeem them for exclusive rewards and prizes.',
  },
  {
    icon: <FaClock />,
    title: 'Happy Hour Deals',
    description: 'Benefit from increased payouts during our special Happy Hour. More chances to win!',
  },
];

const PromotionSection = () => {
  return (
    <section className={styles.promotionSection}>
      <h2 className={styles.title}>Exclusive Promotions</h2>
      <div className={styles.promotionsGrid}>
        {promotions.map((promotion, index) => (
          <div className={styles.promotionCard} key={index}>
            <div className={styles.iconContainer}>
              <div className={styles.icon}>{promotion.icon}</div>
            </div>
            <div className={styles.cardContent}>
              <h3 className={styles.promotionTitle}>{promotion.title}</h3>
              <p className={styles.description}>{promotion.description}</p>
              {/* <button className={styles.learnMoreButton}>Learn More</button> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PromotionSection;
