import React, { useState } from "react";
import { Input, Button, message, Alert } from "antd";
import { FiArrowDownCircle, FiInfo, FiCreditCard } from "react-icons/fi";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import styles from "./AddFund.module.css";
import { createOrder } from "../../services/payment.service"; // Import your API service

const AddFund = ({ isSidebarOpen }) => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  const presetAmounts = [500, 1000, 1500, 2000, 2500, 3000];

  const handlePresetAmountClick = (value) => {
    setAmount(value);
  };

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    setAmount(value);
  };

  const handlePayNow = async () => {
    if (!amount || amount < 500) {
      message.error("The minimum amount to add funds is ₹500.");
      return;
    }

    try {
      const response = await createOrder({ amount }); // Call API to create order

      
      if (response.statusCode === 200) {

        localStorage.setItem('orderId', response.data.orderId);

        navigate("/payment", {
          state: {
            sessionId: response.data.sessionId,
            amount: response.data.amount,
            orderId: response.data.orderId,
          },
        });
      } else {
        message.error("Failed to create order. Please try again.");
      }
    } catch (error) {
      message.error(
        "An error occurred while creating the order. Please try again."
      );
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={`${styles.addFundContainer} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>Add Funds</h2>
        <p className={styles.subtitle}>
          Securely add funds to your account using your preferred UPI app.
          Ensure the amount is ₹500 or more.
        </p>
      </div>

      <div className={styles.presetAmountsContainer}>
        {presetAmounts.map((value) => (
          <Button
            key={value}
            className={`${styles.presetAmountButton} ${
              amount === value ? styles.activePreset : ""
            }`}
            onClick={() => handlePresetAmountClick(value)}
          >
            ₹{value}
          </Button>
        ))}
      </div>

      <div className={styles.inputContainer}>
        <Input
          type="number"
          placeholder="Enter Custom Amount (Min ₹500)"
          value={amount}
          onChange={handleAmountChange}
          className={styles.inputField}
          min={500}
          prefix={<FiCreditCard />}
          size="large"
        />
      </div>

      <Button
        type="primary"
        icon={<FiArrowDownCircle />}
        onClick={handlePayNow}
        className={styles.payButton}
        size="large"
      >
        Pay Now
      </Button>

      <Alert
        message="Need Help?"
        description={
          <span>
            Facing any issues related to adding funds or withdrawing? Your money
            is safe and secure with us as we are PCI DSS certified. If your
            funds are stuck, you don't need to worry. Contact our support, and
            your query will be resolved within 1-2 hours. You can reach us at{" "}
            <a href="tel:+911234567890" className={styles.supportLink}>
              +91 12345 67890
            </a>{" "}
            or via email at{" "}
            <a
              href="mailto:support@yourdomain.com"
              className={styles.supportLink}
            >
              support@yourdomain.com
            </a>
            .
          </span>
        }
        type="info"
        showIcon
        icon={<FiInfo />}
        className={styles.helpAlert}
      />
    </div>
  );
};

export default AddFund;
