import Sidebar from "./components/common/Sidebar/Sidebar";
import React, { useState } from "react";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import ScrollToTop from "./components/common/ScrollToTop";
import { useSelector } from "react-redux";
import "./App.css";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const { isLoggedIn } = useSelector((state) => state.auth);

  // const isLoggedIn = false;

  return (
    <Router>
      <ScrollToTop /> {/* Wrap your app in Router */}
      {isLoggedIn ? (
        <React.Fragment>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

          <PrivateRoutes
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </React.Fragment>
      ) : (
        <>
          <PublicRoutes isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <Navigate to="/" />
        </>
      )}
    </Router>
  );
}

export default App;
