import React, { useState, useEffect } from "react";
import { Alert, Button, Spin } from "antd";
import { FiCheckCircle, FiXCircle, FiClock } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styles from "./PaymentStatus.module.css";
import { checkOrderStatus } from "../../services/payment.service";
import { useDispatch } from "react-redux";
import { fetchUserBalance } from "../../store/balanceSlice"; // Import the balance fetch action

const PaymentStatus = ({ isSidebarOpen }) => {
  const [status, setStatus] = useState("LOADING");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const orderId = localStorage.getItem("orderId");
      if (!orderId) {
        setStatus("FAILED");
        return;
      }

      try {
        const response = await checkOrderStatus(orderId);
        if (response.data.orderStatus === "PAID") {
          setStatus("SUCCESS");
          dispatch(fetchUserBalance()); // Fetch the updated balance
          localStorage.removeItem("orderId");
        } else if (response.data.orderStatus === "FAILED") {
          setStatus("FAILED");
        } else {
          setStatus("PENDING");
        }
      } catch (error) {
        setStatus("FAILED");
      }
    };

    fetchPaymentStatus();
  }, [dispatch]);

  const getStatusDetails = () => {
    switch (status) {
      case "SUCCESS":
        return {
          type: "success",
          message: "Payment Successful!",
          description:
            "Your payment was processed successfully. Thank you for your purchase.",
          icon: <FiCheckCircle className={styles.iconSuccess} />,
          buttonText: "OK",
        };
      case "FAILED":
        return {
          type: "error",
          message: "Payment Failed",
          description:
            "Unfortunately, your payment could not be processed. Please try again or contact support.",
          icon: <FiXCircle className={styles.iconError} />,
          buttonText: "Retry",
        };
      case "PENDING":
        return {
          type: "info",
          message: "Payment Pending",
          description:
            "Your payment is currently being processed. Please wait a moment.",
          icon: <FiClock className={styles.iconPending} />,
          buttonText: "Close",
        };
      case "LOADING":
        return {
          type: "info",
          message: "Processing Payment...",
          description:
            "Please do not close or refresh this page while we process your payment.",
          icon: <Spin className={styles.iconLoading} />,
        };
      default:
        return {
          type: "warning",
          message: "Unknown Status",
          description: "The payment status is unclear. Please contact support.",
          icon: <FiClock className={styles.iconPending} />,
          buttonText: "Close",
        };
    }
  };

  const statusDetails = getStatusDetails();

  const handleAction = () => {
    if (status === "FAILED") {
      navigate("/add-fund");
    } else {
      navigate("/home");
    }
  };

  return (
    <div
      className={`${styles.paymentStatusContainer} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <div className={styles.statusContent}>
        <Alert
          message={statusDetails.message}
          description={statusDetails.description}
          type={statusDetails.type}
          showIcon
          icon={statusDetails.icon}
          className={styles.statusAlert}
        />
        {status !== "LOADING" && (
          <Button
            type="primary"
            onClick={handleAction}
            className={styles.actionButton}
          >
            {statusDetails.buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentStatus;
