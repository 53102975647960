import React from 'react';
import styles from './Footer.module.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaTrophy, FaGamepad, FaCrown } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.topSection}>
          <div className={styles.logoSection}>
            <h2 className={styles.logo}>MatkaMaster</h2>
            <p className={styles.tagline}>
              Experience the thrill of Satta Matka with expert predictions, exciting games, and the best gameplay experience.
            </p>
          </div>
          <div className={styles.linksSection}>
            <div className={styles.column}>
              <h3 className={styles.columnTitle}>Quick Links</h3>
              <ul className={styles.linkList}>
                <li className={styles.linkItem}>Home</li>
                <li className={styles.linkItem}>Games</li>
                <li className={styles.linkItem}>Results</li>
                <li className={styles.linkItem}>How to Play</li>
                <li className={styles.linkItem}>Terms & Conditions</li>
              </ul>
            </div>
            <div className={styles.column}>
              <h3 className={styles.columnTitle}>Popular Games</h3>
              <ul className={styles.linkList}>
                <li className={styles.linkItem}><FaTrophy className={styles.icon} /> Kalyan Matka</li>
                <li className={styles.linkItem}><FaCrown className={styles.icon} /> Rajdhani Night</li>
                <li className={styles.linkItem}><FaGamepad className={styles.icon} /> Milan Day</li>
                <li className={styles.linkItem}><FaTrophy className={styles.icon} /> Time Bazar</li>
                <li className={styles.linkItem}><FaCrown className={styles.icon} /> Main Bazar</li>
              </ul>
            </div>
            <div className={styles.column}>
              <h3 className={styles.columnTitle}>Resources</h3>
              <ul className={styles.linkList}>
                <li className={styles.linkItem}>FAQ</li>
                <li className={styles.linkItem}>Game Rules</li>
                <li className={styles.linkItem}>Privacy Policy</li>
                <li className={styles.linkItem}>Responsible Gaming</li>
              </ul>
            </div>
          </div>
          <div className={styles.socialSection}>
            <h3 className={styles.columnTitle}>Stay Connected</h3>
            <div className={styles.socialIcons}>
              <FaFacebookF className={styles.socialIcon} />
              <FaTwitter className={styles.socialIcon} />
              <FaInstagram className={styles.socialIcon} />
              <FaYoutube className={styles.socialIcon} />
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p className={styles.copyright}>
            &copy; 2024 MatkaMaster. All rights reserved. | Designed with passion and precision.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
