import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  notification,
  Radio,
} from "antd";
import moment from "moment"; // For handling date formatting
import styles from "./BidPlacementForm.module.css";
import { useLocation } from "react-router-dom";
import { placeBids } from "../../services/bid.service";
const { Option } = Select;

const BidPlacementForm = ({ isSidebarOpen }) => {
  const [form] = Form.useForm();
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [digitAmounts, setDigitAmounts] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const location = useLocation();

  const { item } = location.state || {};

  const searchParams = new URLSearchParams(location.search);

  const marketName = searchParams.get("market");
  const mid = searchParams.get("mid");

  const onFinish = async (values) => {
    const selectedDigits = Object.keys(digitAmounts).map((digit) => ({
      digit: parseInt(digit, 10),
      amount: digitAmounts[digit],
    }));

    const formData = {
      bidForDate: values.date.toDate(),
      betType: item._id, // Assuming this is a fixed value
      betOption: values.betOption.toUpperCase(),
      selectedDigits: selectedDigits,
    };

    try {
      // Call the API to submit the formData
      const response = await placeBids(mid, formData);

      console.log(response)
      if (response.statusCode == 200) {
        notification.success({
          message: "Bid Placed Successfully",
          description: `Your bid details: \nDate: ${formData.bidForDate}, Bet Option: ${formData.betOption}, Total Amount: ₹${totalAmount}`,
        });
        form.resetFields();
        resetBids();
      } else if (response.statusCode == 400) {
        notification.error({
          message: response.message,
          description: response.description,
        });
      } else {
        throw new Error(response.message || "Failed to place bid.");
      }
    } catch (error) {
      notification.error({
        message: "Bid Placement Failed",
        description: error.message,
      });
    }
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleDigitClick = (digit) => {
    if (!selectedAmount) {
      notification.warning({
        message: "No Amount Selected",
        description: "Please select an amount before choosing a digit.",
      });
      return;
    }

    const newDigitAmounts = { ...digitAmounts };
    if (!newDigitAmounts[digit]) {
      newDigitAmounts[digit] = selectedAmount || 0;
    } else {
      newDigitAmounts[digit] += selectedAmount || 0;
    }
    setDigitAmounts(newDigitAmounts);
    calculateTotalAmount(newDigitAmounts);
  };

  const handleAmountChange = (digit, value) => {
    const newDigitAmounts = { ...digitAmounts, [digit]: Number(value) || 0 };
    setDigitAmounts(newDigitAmounts);
    calculateTotalAmount(newDigitAmounts);
  };

  const calculateTotalAmount = (amounts) => {
    const total = Object.values(amounts).reduce((sum, amt) => sum + amt, 0);
    setTotalAmount(total);
  };

  const resetBids = () => {
    setDigitAmounts({});
    setTotalAmount(0);
    setSelectedAmount(null);
  };

  return (
    <Form
      form={form}
      name="bidPlacementForm"
      onFinish={onFinish}
      layout="vertical"
      className={`${styles.form} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      {/* First Row: Date Picker and Bet Option */}
      <div className={styles.row}>
        <Form.Item
          name="date"
          label="Select Date"
          rules={[{ required: true, message: "Please select a date!" }]}
          className={styles.halfItem}
        >
          <DatePicker className={styles.input} />
        </Form.Item>

        <Form.Item
          name="betOption"
          label="Bet Option"
          rules={[{ required: true, message: "Please select a bet option!" }]}
          className={styles.halfItem}
        >
          <Select placeholder="Select Bet Option" className={styles.input}>
            <Option value="open">Open</Option>
            <Option value="close">Close</Option>
          </Select>
        </Form.Item>
      </div>

      {/* Second Row: Amount Input with Options */}
      <Form.Item
        name="amount"
        label="Select Base Amount"
        rules={[
          { required: true, message: "Please select or enter an amount!" },
        ]}
      >
        <Radio.Group
          className={styles.amountGroup}
          onChange={(e) => handleAmountSelect(e.target.value)}
        >
          {[10, 20, 50, 100, 200, 500, 1000, 1500, 2000].map((amount) => (
            <Radio.Button key={amount} value={amount}>
              ₹{amount}
            </Radio.Button>
          ))}
          <Input
            className={styles.customAmountInput}
            placeholder="Custom"
            prefix="₹"
            style={{ width: 100 }}
            onChange={(e) => handleAmountSelect(Number(e.target.value))}
          />
        </Radio.Group>
      </Form.Item>

      {/* Digit Section: Heading and Digits */}
      <div className={styles.digitSection}>
        <h3 className={styles.digitHeading}>Select Your Lucky Digit</h3>
        <div className={styles.digitButtons}>
          {item.allowedNumbers.map((digit) => (
            <div key={digit} className={styles.digitBidContainer}>
              <Button
                className={`${styles.digitButton} ${
                  digitAmounts[digit]
                    ? styles.selectedDigit
                    : styles.notSelectedDigit
                }`}
                onClick={() => handleDigitClick(digit)}
              >
                {digit}
              </Button>
              <Input
                className={styles.amountInput}
                value={digitAmounts[digit] || ""}
                placeholder="0"
                onChange={(e) => handleAmountChange(digit, e.target.value)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Total Amount and Buttons */}
      <div className={styles.totalAmountContainer}>
        <span className={styles.totalAmount}>Total Amount: ₹{totalAmount}</span>
        <div className={styles.buttonContainer}>
          <Button onClick={resetBids} className={styles.resetButton}>
            Reset
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.submitButton}
          >
            Place Bid
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default BidPlacementForm;
