// src/store/balanceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserBalance } from '../services/account.service';

export const fetchUserBalance = createAsyncThunk(
  'balance/fetchUserBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserBalance();
      console.log(response)
      return response.data.balance;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const balanceSlice = createSlice({
  name: 'balance',
  initialState: {
    value: 0,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setBalance(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBalance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserBalance.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload;
      })
      .addCase(fetchUserBalance.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setBalance } = balanceSlice.actions;

export default balanceSlice.reducer;
