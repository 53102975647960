import React, { useState } from "react";
import { Modal, Button, Input, Form, Select, Typography, Tooltip, message } from "antd";
import { FaInfoCircle, FaPhone } from "react-icons/fa";
import styles from "./WithdrawModal.module.css";
import { withdrawFundRequest } from "../../services/account.service";  // Assuming this is correctly imported
const { Option } = Select;
const { Text, Title } = Typography;

const WithdrawModal = ({ visible, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [withdrawType, setWithdrawType] = useState(null);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          requestType: "withdraw_fund",
          amount: values.amount,
          currency: "INR",
          paymentMethod: withdrawType,
          requestRemarks: values.remarks || "N/A",
          ...(withdrawType === "upi" && { upiId: values.upiId }),
          ...(withdrawType === "bank" && {
            accountHolderName: values.accountHolderName,
            accountNumber: values.accountNumber,
            ifscCode: values.ifscCode,
          }),
        };

        // Call the API method to submit the withdrawal request
        withdrawFundRequest(payload)
          .then(() => {
            message.success("Withdrawal request submitted successfully!");
            form.resetFields();
            setWithdrawType(null); // Reset after submission
            onSubmit(values); // Optional: if you want to pass back the values
            onClose();
          })
          .catch((error) => {
            message.error("Failed to submit withdrawal request. Please try again.");
            console.error("API Error:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleWithdrawTypeChange = (value) => {
    setWithdrawType(value);
  };

  return (
    <Modal
      title="Withdraw Funds"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      okText="Withdraw"
      cancelText="Cancel"
      className={styles.withdrawModal}
      centered
    >
      {/* Highlighted Information Section */}
      <div className={styles.infoSection}>
        <Title level={5} className={styles.infoTitle}>Important Information</Title>
        <div className={styles.infoDetails}>
          <Text>
            <FaInfoCircle /> Minimum Withdrawal Limit: ₹1000
          </Text>
          <Text>
            <FaInfoCircle /> Maximum Withdrawal Limit: ₹1,00,000
          </Text>
          <Text>
            <FaInfoCircle /> Processing Time: 1-3 Business Days
          </Text>
          <Text>
            <FaInfoCircle /> Available Days: Monday to Friday
          </Text>
        </div>
      </div>

      {/* Withdrawal Form */}
      <Form form={form} layout="vertical" className={styles.withdrawForm}>
        <Form.Item
          label={
            <span>
              Amount (INR) &nbsp;
              <Tooltip title="Enter the amount you wish to withdraw in INR.">
                <FaInfoCircle />
              </Tooltip>
            </span>
          }
          name="amount"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <Input type="number" placeholder="Enter amount to withdraw" prefix="₹" />
        </Form.Item>

        <Form.Item
          label="Withdraw Method"
          name="withdrawType"
          rules={[{ required: true, message: "Please select a withdraw method" }]}
        >
          <Select placeholder="Select Withdraw Method" onChange={handleWithdrawTypeChange}>
            <Option value="upi">UPI</Option>
            <Option value="bank">Bank Account</Option>
          </Select>
        </Form.Item>

        {withdrawType === "upi" && (
          <Form.Item
            label="UPI ID"
            name="upiId"
            rules={[{ required: true, message: "Please enter your UPI ID" }]}
          >
            <Input placeholder="Enter your UPI ID" />
          </Form.Item>
        )}

        {withdrawType === "bank" && (
          <>
            <Form.Item
              label="Account Holder Name"
              name="accountHolderName"
              rules={[{ required: true, message: "Please enter account holder's name" }]}
            >
              <Input placeholder="Enter account holder's name" />
            </Form.Item>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[{ required: true, message: "Please enter your account number" }]}
            >
              <Input type="number" placeholder="Enter account number" />
            </Form.Item>
            <Form.Item
              label="IFSC Code"
              name="ifscCode"
              rules={[{ required: true, message: "Please enter IFSC code" }]}
            >
              <Input placeholder="Enter IFSC code" />
            </Form.Item>
          </>
        )}

        <Form.Item label="Remarks" name="remarks">
          <Input.TextArea placeholder="Enter any remarks" rows={4} />
        </Form.Item>
      </Form>

      {/* Help and Support Section */}
      <div className={styles.helpSection}>
        <Text strong>
          Need Help? Contact Support:{" "}
          <a href="tel:+918001234567">
            <FaPhone /> +91 800 123 4567
          </a>
        </Text>
      </div>
    </Modal>
  );
};

export default WithdrawModal;
